import React from "react"
import Menu from "./menu"

export default () => (
  <div id="footerbk">
    <div id="footer">
      <div className="holderfooup">
        <div className="menu-meniu-secundar-container">
          <Menu menuId="footermenu" />
        </div>
        <form id="search-form" action="index.html">
          <label>search</label>
          <fieldset>
            <input className="fieldone" type="text" name="s" />
            <button className="fieldtwo" type="submit">
              go!
            </button>
          </fieldset>
        </form>
      </div>

      <div id="copyright">
        Copyright &#169; {new Date().getFullYear()}
        <a href="index.html" title="getfresh.ro" rel="home">
          getfresh.ro
        </a>
        All rights reserved.
      </div>
      <div id="followuswrap">
        <span>follow us</span>
        <ul id="followus">
          <li>
            <a className="twt" href="index.html">
              twitter
            </a>
          </li>
          <li>
            <a className="fbk" href="index.html#">
              Facebook
            </a>
          </li>
          <li>
            <a className="lkin" href="index.html#">
              linkedin
            </a>
          </li>
          <li className="rss">
            <a href="index.html">rss</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

/* <Link
to="/"
style={{
  color: `white`,
  textDecoration: `none`,
}}
>
{siteTitle}
</Link> */
