import PropTypes from "prop-types"
import React from "react"
import "../../assets/css/reset.css"
import "../../assets/css/structure.css"
import "../../assets/css/style.css"
import "../../assets/css/typography.css"
import "../../assets/css/vendor/elegant/colorbox.css"
import Footer from "../footer"
import Header from "../header"

const Layout = ({ isHome, pageId, children, logo }) => (
  <>
    <div id={pageId}>
      <Header isHome={isHome} logo={logo} />

      <main>{children}</main>

      <Footer />
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
