import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import logo from "../../assets/css/images/foliologo.png"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import digitalDesign from "../../images/digital-design-large.jpg"
import customApplications from "../../images/custom-applications-large.png"
import ecommerce from "../../images/ecommerce-large.jpg"
import enterpriseSecurity from "../../images/enterprise-security-large.jpg"
import managedInfrastructure from "../../images/managed-infrastructure-large.png"

export default () => (
  <Layout isHome={false} pageId="foliopage" logo={logo}>
    <SEO title="Folio" />
    <div id="content">
      <div id="folio_show">
        <div id="content_mask">
          <ul id="content_article">
            {[
              {
                link: "/solutions/digital-design",
                title: "Digital Design",
                image: digitalDesign,
                text: `We partner with clients whose own work enhances society. Whose
                projects continue to inspire and challenge our team with increasingly
                interesting and complex problems.`,
              },
              {
                link: "/solutions/custom-applications",
                title: "Custom Applications",
                image: customApplications,
                text: `Grow new business with mobile applications, responsive websites, and
                custom software. Our team of business process consultants, project
                managers, designers, and engineers will create software that fully
                realizes the objectives of your project and provides real, measurable
                value.`,
              },
              {
                link: "/solutions/ecommerce",
                title: "Ecommerce",
                image: ecommerce,
                text: `We power the online sales of our clients. Our expertise is rooted in
                the years of experience building highly effective online marketplaces
                that stand above the crowd.`,
              },
              {
                link: "/solutions/managed-infrastructure",
                title: "Managed Infrastructure",
                image: managedInfrastructure,
                text: `Build and maintain resilient infrastructure for your software. Whether
                you’re looking for someone to manage your existing servers, or
                planning to build new infrastructure, our team of dedicated
                engineers will keep you up and running.`,
              },
              {
                link: "/solutions/enterprise-security",
                title: "Enterprise Security",
                image: enterpriseSecurity,
                text: `The challenges to ensuring the security, privacy, and integrity of
                technology systems have never been greater. A growing reliance on
                networked computers has given rise to greater vulnerability and
                created formidable new threats.`,
              },
            ].map(solution => (
              <li key={solution.link}>
                <AniLink fade to={solution.link}>
                  <div className="wrap_blog_text">
                    <div className="blogimg">
                      <img
                        src={solution.image}
                        className="attachment-folio-thumbnail size-folio-thumbnail wp-post-image"
                        alt={solution.title}
                      />
                    </div>

                    <h1>{solution.title}</h1>
                    <p>{solution.text}</p>
                  </div>
                </AniLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)
