import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"

export default ({ menuId }) => (
  <ul id={menuId} className="menu">
    <li>
      <AniLink fade activeClassName="current-menu-item" to="/">
        Home
      </AniLink>
    </li>
    <li>
      <AniLink fade activeClassName="current-menu-item" to="/services">
        Services
      </AniLink>
    </li>
    <li>
      <AniLink fade activeClassName="current-menu-item" to="/solutions">
        Solutions
      </AniLink>
    </li>
    <li>
      <AniLink fade activeClassName="current-menu-item" to="/blog">
        Blog
      </AniLink>
    </li>
    <li>
      <AniLink fade activeClassName="current-menu-item" to="/contact">
        Contact
      </AniLink>
    </li>
  </ul>
)
