import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import React from "react"
import Menu from "./menu"

const Header = ({ isHome, logo }) => (
  <>
    <div id="header">
      <div id="lightwrite">
        <div id="in_header">
          <div id={isHome ? "label" : "lamp"} />
          <div id="bowls" />
        </div>
      </div>
    </div>

    <div id="logmenu">
      <div id="logo">
        <AniLink fade to="/" title="getfresh.ro" rel="home">
          <img src={logo} alt="getfresh.ro" />
        </AniLink>
      </div>
      <div className="menu-meniu-principal-container">
        <Menu menuId="menu" />
      </div>
    </div>
  </>
)

/* <Link
to="/"
style={{
  color: `white`,
  textDecoration: `none`,
}}
>
{siteTitle}
</Link> */

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
